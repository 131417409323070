import {
  CLOSE_FIRST_LAYER_MODAL, CLOSE_FOURTH_LAYER_MODAL,
  CLOSE_SECOND_LAYER_MODAL, CLOSE_THIRD_LAYER_MODAL,
  OPEN_FIRST_LAYER_MODAL, OPEN_FOURTH_LAYER_MODAL,
  OPEN_SECOND_LAYER_MODAL, OPEN_THIRD_LAYER_MODAL,
  START_FIRST_LAYER_MODAL_LISTENING, START_FOURTH_LAYER_MODAL_LISTENING,
  START_SECOND_LAYER_MODAL_LISTENING, START_THIRD_LAYER_MODAL_LISTENING,
  STOP_FIRST_LAYER_MODAL_LISTENING, STOP_FOURTH_LAYER_MODAL_LISTENING,
  STOP_SECOND_LAYER_MODAL_LISTENING, STOP_THIRD_LAYER_MODAL_LISTENING
} from "state/ui/uiConstants";

const INIT_STATE = {
  firstLayer: false,
  firstLayerListening: false,
  secondLayer: false,
  secondLayerListening: false,
  thirdLayer: false,
  thirdLayerListening: false,
  fourthLayer: false,
  fourthLayerListening: false,
};

const ModalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_FIRST_LAYER_MODAL_LISTENING:
      return {
        ...state,
        firstLayerListening: true,
      };
    case STOP_FIRST_LAYER_MODAL_LISTENING:
      return {
        ...state,
        firstLayerListening: false,
      };
    case OPEN_FIRST_LAYER_MODAL:
      return {
        ...state,
        firstLayer: true,
      };
    case CLOSE_FIRST_LAYER_MODAL:
      return {
        ...state,
        firstLayer: false,
      };

    case START_SECOND_LAYER_MODAL_LISTENING:
      return {
        ...state,
        secondLayerListening: true,
      };
    case STOP_SECOND_LAYER_MODAL_LISTENING:
      return {
        ...state,
        secondLayerListening: false,
      };
    case OPEN_SECOND_LAYER_MODAL:
      return {
        ...state,
        secondLayer: true,
      };
    case CLOSE_SECOND_LAYER_MODAL:
      return {
        ...state,
        secondLayer: false,
      };

    case START_THIRD_LAYER_MODAL_LISTENING:
      return {
        ...state,
        thirdLayerListening: true,
      };
    case STOP_THIRD_LAYER_MODAL_LISTENING:
      return {
        ...state,
        thirdLayerListening: false,
      };
    case OPEN_THIRD_LAYER_MODAL:
      return {
        ...state,
        thirdLayer: true,
      };
    case CLOSE_THIRD_LAYER_MODAL:
      return {
        ...state,
        thirdLayer: false,
      };

    case START_FOURTH_LAYER_MODAL_LISTENING:
      return {
        ...state,
        fourthLayerListening: true,
      };
    case STOP_FOURTH_LAYER_MODAL_LISTENING:
      return {
        ...state,
        fourthLayerListening: false,
      };
    case OPEN_FOURTH_LAYER_MODAL:
      return {
        ...state,
        fourthLayer: true,
      };
    case CLOSE_FOURTH_LAYER_MODAL:
      return {
        ...state,
        fourthLayer: false,
      };

    default:
      return { ...state };
  }
};

export default ModalReducer;
