import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PRODUCT_GIFT_CARDS } from "state/model/productGiftCards/productGiftCards/constants";
import { apiListProductGiftCards } from "api/model/productGiftCard/apiProductGiftCard";
import { listProductGiftCardsFailed, listProductGiftCardsSuccess } from "state/model/productGiftCards/productGiftCards/productGiftCardsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* productGiftCardsSaga() {
  yield all([
    fork(watchListProductGiftCards),
  ]);
}

// List ProductGiftCards
export function* watchListProductGiftCards() {
  yield takeEvery(LIST_PRODUCT_GIFT_CARDS, sagaListProductGiftCards);
}

export function* sagaListProductGiftCards() {
  try {
    const response = yield call(apiListProductGiftCards);

    const { productGiftCards } = response.data;

    yield put(listProductGiftCardsSuccess({ productGiftCards }));

  } catch (e) {
    yield put(listProductGiftCardsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default productGiftCardsSaga;
