import React from "react";
import { INDEX_PRODUCT_GIFT_CARDS_PATH, SHOW_PRODUCT_GIFT_CARD_PATH } from "routes/productGiftCard/constants";
import { Route } from "react-router-dom";

const ProductGiftCards = React.lazy(() => import('pages/productGiftCards/index/ProductGiftCards'));
const NewProductGiftCard = React.lazy(() => import('pages/productGiftCards/new/NewProductGiftCard'));
const ShowProductGiftCard = React.lazy(() => import('pages/productGiftCards/show/ShowProductGiftCard'));

export const productGiftCardRoutes = {
  name: 'Shop',
  icon: 'far fa-store',
  path: INDEX_PRODUCT_GIFT_CARDS_PATH,
  exact: true,
  component: ProductGiftCards,
  route: Route,
  public: true,
};

export const hiddenProductGiftCardRoutes = {
  children: [
    {
      path: SHOW_PRODUCT_GIFT_CARD_PATH,
      exact: true,
      name: 'Show ProductGiftCard',
      component: ShowProductGiftCard,
      route: Route,
      public: true,
    }
  ]
}
