import {
  FETCH_LINE_ATTACHMENT,
  FETCH_LINE_ATTACHMENT_FAILED,
  FETCH_LINE_ATTACHMENT_SUCCESS,
  INIT_LINE_ATTACHMENT
} from "state/model/lineAttachments/lineAttachment/constants";

const INIT_STATE = {
  lineAttachment: {},
  loading: true,
  error: null,
};

const lineAttachmentReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ATTACHMENT:
      return INIT_STATE;

    case FETCH_LINE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineAttachment: action.payload.lineAttachment,
      };

    case FETCH_LINE_ATTACHMENT_FAILED:
      return {
        ...state,
        lineAttachment: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default lineAttachmentReducers;
