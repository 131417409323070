import _ from 'lodash';
import {
  CREATE_RECEIVED_NOTICE_SUCCESS,
  DELETE_RECEIVED_NOTICE_SUCCESS, RECEIVE_NOTICE_SUCCESS, UPDATE_RECEIVED_NOTICE_READ,
  UPDATE_RECEIVED_NOTICE_SUCCESS
} from "state/model/receivedNotices/receivedNotice/constants";
import {
  CLEAR_ALL_RECEIVED_NOTICES,
  INIT_RECEIVED_NOTICES,
  LIST_RECEIVED_NOTICES,
  LIST_RECEIVED_NOTICES_FAILED,
  LIST_RECEIVED_NOTICES_SUCCESS
} from "state/model/receivedNotices/receivedNotices/constants";
import { REFRESH_USER } from "state/auth/authConstants";

const INIT_STATE = {
  topBarUnreadNotices: {},
  receivedNotices: {},
  loading: true,
  error: null,
};

const receivedNoticesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_RECEIVED_NOTICES:
      return INIT_STATE;

    case LIST_RECEIVED_NOTICES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_RECEIVED_NOTICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        receivedNotices: _.mapKeys(action.payload.receivedNotices, 'id'),
      };

    case LIST_RECEIVED_NOTICES_FAILED:
      return {
        ...state,
        receivedNotices: {},
        loading: false,
        error: action.payload.error,
      };

    case REFRESH_USER:
      return {
        ...state,
        loading: false,
        error: null,
        topBarUnreadNotices: _.mapKeys(action.payload.current.receivedUnreadNotices, 'id'),
      };

    case RECEIVE_NOTICE_SUCCESS:
      return {
        ...state,
        topBarUnreadNotices: {
          [action.payload.receivedNotice.id]: action.payload.receivedNotice,
          ...state.topBarUnreadNotices,
        },
        receivedNotices: {
          [action.payload.receivedNotice.id]: action.payload.receivedNotice,
          ...state.receivedNotices,
        },
      };

    case UPDATE_RECEIVED_NOTICE_READ:
      return {
        ...state,
        topBarUnreadNotices: _.omit(state.topBarUnreadNotices, action.payload.receivedNotice.id),
        receivedNotices: {
          ...state.receivedNotices,
          [action.payload.receivedNotice.id]: {
            ...state.receivedNotices[action.payload.receivedNotice.id],
            isRead: true,
          }
        }
      };

    case CLEAR_ALL_RECEIVED_NOTICES:
      return {
        ...state,
        topBarUnreadNotices: {},
        receivedNotices: _.chain(Object.values(state.receivedNotices))
          .map((notice) => {
            return notice.isRead ? notice : { ...notice, isRead: true }
          })
          .mapKeys('id')
          .value(),
      };

    case CREATE_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        receivedNotices: {
          [action.payload.receivedNotice.id]: action.payload.receivedNotice,
          ...state.receivedNotices,
        },
      };

    case UPDATE_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        receivedNotices: {
          ...state.receivedNotices,
          [action.payload.receivedNotice.id]: action.payload.receivedNotice,
        },
      };

    case DELETE_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        receivedNotices: _.omit(state.receivedNotices, action.payload.receivedNotice.id)
      };

    default:
      return { ...state };
  }
};

export default receivedNoticesReducers;
