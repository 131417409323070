import {
  CHANGE_PASSWORD_API,
  CHECK_USER_API,
  FORGET_PASSWORD_API,
  RESET_PASSWORD_API,
  SIGN_IN_USER_API,
  SIGN_OUT_USER_API,
} from "api/auth/constants";
import api from "api/api";

export const apiSignInUser = ({ username, password }) => {
  const { url, method } = SIGN_IN_USER_API;

  return api({
    method,
    url,
    data: {
      auth: {
        username: username,
        password: password,
      }
    }
  });
};

export const apiSignOutUser = () => {
  const { url, method } = SIGN_OUT_USER_API;

  return api({
    method,
    url,
  });
};

export const apiCheckUser = () => {
  const { url, method } = CHECK_USER_API;

  return api({
    method,
    url,
  });
};
export const apiForgetPassword = ({ username }) => {
  const { url, method } = FORGET_PASSWORD_API;

  return api({
    method,
    url,
    data: {
      username: username
    }
  });
};

export const apiResetPassword = ({ resetPasswordToken, password, passwordConfirmation }) => {
  const { url, method } = RESET_PASSWORD_API;

  return api({
    method,
    url,
    data: {
      auth: { resetPasswordToken, password, passwordConfirmation }
    }
  });
};

export const apiChangePassword = ({ currentPassword, password, passwordConfirmation }) => {
  const { url, method } = CHANGE_PASSWORD_API;

  return api({
    method,
    url,
    data: {
      auth: { currentPassword, password, passwordConfirmation }
    }
  });
};

// export const apiFetchCurrentStaff = () => {
//   const { url, method } = FETCH_CURRENT_STAFF_API;
//
//   return api({
//     method,
//     url: url,
//   });
// };
