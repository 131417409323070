import {
  CREATE_LINE_ATTACHMENT,
  CREATE_LINE_ATTACHMENT_FAILED,
  CREATE_LINE_ATTACHMENT_SUCCESS, CREATE_ORDER_ATTACHMENT,
  CREATE_PACK_ATTACHMENT,
  DELETE_LINE_ATTACHMENT,
  DELETE_LINE_ATTACHMENT_FAILED,
  DELETE_LINE_ATTACHMENT_SUCCESS,
  DOWNLOAD_LINE_ATTACHMENT,
  FETCH_LINE_ATTACHMENT,
  FETCH_LINE_ATTACHMENT_FAILED,
  FETCH_LINE_ATTACHMENT_SUCCESS,
  INIT_LINE_ATTACHMENT,
  UPDATE_LINE_ATTACHMENT,
  UPDATE_LINE_ATTACHMENT_FAILED,
  UPDATE_LINE_ATTACHMENT_SUCCESS
} from "state/model/lineAttachments/lineAttachment/constants";

export const initLineAttachment = () => ({
  type: INIT_LINE_ATTACHMENT,
  payload: {}
});

export const createLineAttachment = ({ lineAttachment }) => ({
  type: CREATE_LINE_ATTACHMENT,
  payload: { lineAttachment }
});

export const createPackAttachment = ({ lineAttachment }) => ({
  type: CREATE_PACK_ATTACHMENT,
  payload: { lineAttachment }
});

export const createOrderAttachment = ({ lineAttachment }) => ({
  type: CREATE_ORDER_ATTACHMENT,
  payload: { lineAttachment }
});

export const createLineAttachmentSuccess = ({ lineAttachment }) => ({
  type: CREATE_LINE_ATTACHMENT_SUCCESS,
  payload: { lineAttachment }
});

export const createLineAttachmentFailed = ({ lineAttachment, error }) => ({
  type: CREATE_LINE_ATTACHMENT_FAILED,
  payload: { lineAttachment, error }
});

export const fetchLineAttachment = ({ lineAttachmentId }) => ({
  type: FETCH_LINE_ATTACHMENT,
  payload: { lineAttachmentId }
});

export const fetchLineAttachmentSuccess = ({ lineAttachment }) => ({
  type: FETCH_LINE_ATTACHMENT_SUCCESS,
  payload: { lineAttachment }
});

export const fetchLineAttachmentFailed = ({ error }) => ({
  type: FETCH_LINE_ATTACHMENT_FAILED,
  payload: { error }
});

export const updateLineAttachment = ({ lineAttachment }) => ({
  type: UPDATE_LINE_ATTACHMENT,
  payload: { lineAttachment }
});

export const updateLineAttachmentSuccess = ({ lineAttachment }) => ({
  type: UPDATE_LINE_ATTACHMENT_SUCCESS,
  payload: { lineAttachment }
});

export const updateLineAttachmentFailed = ({ error }) => ({
  type: UPDATE_LINE_ATTACHMENT_FAILED,
  payload: { error }
});

export const deleteLineAttachment = ({ lineAttachment }) => ({
  type: DELETE_LINE_ATTACHMENT,
  payload: { lineAttachment }
});

export const deleteLineAttachmentSuccess = ({ lineAttachment }) => ({
  type: DELETE_LINE_ATTACHMENT_SUCCESS,
  payload: { lineAttachment }
});

export const deleteLineAttachmentFailed = ({ error }) => ({
  type: DELETE_LINE_ATTACHMENT_FAILED,
  payload: { error }
});

export const downloadLineAttachment = ({ lineAttachment }) => ({
  type: DOWNLOAD_LINE_ATTACHMENT,
  payload: { lineAttachment }
});