
export const CREATE_PRODUCT_GIFT_CARD_API = {
  url: `/product/gift_cards`,
  method: 'post'
};

export const INDEX_PRODUCT_GIFT_CARDS_API = {
  url: `/product/gift_cards`,
  method: 'get',
};

export const FETCH_PRODUCT_GIFT_CARD_API = {
  url: `/product/gift_cards/:id`,
  method: 'get',
};

export const UPDATE_PRODUCT_GIFT_CARD_API = {
  url: `/product/gift_cards/:id`,
  method: 'put'
};

export const DELETE_PRODUCT_GIFT_CARD_API = {
  url: `/product/gift_cards/:id`,
  method: 'delete'
};
