
export const CREATE_GIFT_CARD_API = {
  url: `/gift_cards`,
  method: 'post'
};

export const INDEX_GIFT_CARDS_API = {
  url: `/gift_cards`,
  method: 'get',
};

export const FETCH_GIFT_CARD_API = {
  url: `/gift_cards/:id`,
  method: 'get',
};

export const UPDATE_GIFT_CARD_API = {
  url: `/gift_cards/:id`,
  method: 'put'
};

export const DELETE_GIFT_CARD_API = {
  url: `/gift_cards/:id`,
  method: 'delete'
};
