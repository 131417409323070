import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { allFlattenRoutes as routes } from './index';
import * as layoutConstants from 'constants/layout';
import _ from 'lodash';

// All layouts/containers

import AuthLayout from "layouts/Auth";
import VerticalLayout from "layouts/Vertical";
import HorizontalLayout from "layouts/Horizontal";
import DetachedLayout from "layouts/Detached";
import { isUserAuthenticated } from "helpers/authUtils";
import {
  FORGET_PASSWORD_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  RESET_PASSWORD_PATH,
  SETUP_ACCOUNT_PATH
} from "routes/auth/constants";
import history from 'routes/history';

class Routes extends Component {
  // returns the layout
  getLayout = () => {
    // if (!isUserAuthenticated()) return AuthLayout;

    // const authLayoutPath = [
    //   LOGOUT_PATH,
    //   LOGIN_PATH,
    //   FORGET_PASSWORD_PATH,
    //   RESET_PASSWORD_PATH,
    //   SETUP_ACCOUNT_PATH
    // ];
    // if (_.includes(authLayoutPath, window.location.pathname)) return AuthLayout;

    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  shouldComponentUpdate(nextProps, nextState) {
    let oldLayout = { ...this.props.layout };
    delete oldLayout.showRightSidebar;
    let newLayout = { ...nextProps.layout };
    delete newLayout.showRightSidebar;

    return (
      JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
      JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)
    );
  }

  render() {
    const Layout = this.getLayout();

    // rendering the router with layout
    return (
      <Router history={history}>
        <Layout {...this.props}>
          <Switch>
            {routes.map((route, index) => {
              return (
                !route.children && (
                  <route.route
                    key={index}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    component={route.component}></route.route>
                )
              );
            })}
          </Switch>
        </Layout>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(Routes);
