import {
  CREATE_GIFT_CARD,
  CREATE_GIFT_CARD_FAILED,
  CREATE_GIFT_CARD_SUCCESS,
  DELETE_GIFT_CARD, DELETE_GIFT_CARD_FAILED, DELETE_GIFT_CARD_SUCCESS,
  FETCH_GIFT_CARD,
  FETCH_GIFT_CARD_FAILED,
  FETCH_GIFT_CARD_SUCCESS,
  INIT_GIFT_CARD,
  UPDATE_GIFT_CARD,
  UPDATE_GIFT_CARD_FAILED,
  UPDATE_GIFT_CARD_SUCCESS,
  ASSIGN_GIFT_CARD
} from "state/model/giftCards/giftCard/constants";

export const initGiftCard = () => ({
  type: INIT_GIFT_CARD,
  payload: {}
});

export const createGiftCard = ({ giftCard }) => ({
  type: CREATE_GIFT_CARD,
  payload: { giftCard }
});

export const createGiftCardSuccess = ({ giftCard }) => ({
  type: CREATE_GIFT_CARD_SUCCESS,
  payload: { giftCard }
});

export const createGiftCardFailed = ({ error }) => ({
  type: CREATE_GIFT_CARD_FAILED,
  payload: { error }
});

export const fetchGiftCard = ({ giftCardId }) => ({
  type: FETCH_GIFT_CARD,
  payload: { giftCardId }
});

export const fetchGiftCardSuccess = ({ giftCard }) => ({
  type: FETCH_GIFT_CARD_SUCCESS,
  payload: { giftCard }
});

export const fetchGiftCardFailed = ({ error }) => ({
  type: FETCH_GIFT_CARD_FAILED,
  payload: { error }
});

export const updateGiftCard = ({ giftCard }) => ({
  type: UPDATE_GIFT_CARD,
  payload: { giftCard }
});

export const updateGiftCardSuccess = ({ giftCard }) => ({
  type: UPDATE_GIFT_CARD_SUCCESS,
  payload: { giftCard }
});

export const updateGiftCardFailed = ({ error }) => ({
  type: UPDATE_GIFT_CARD_FAILED,
  payload: { error }
});

export const deleteGiftCard = ({ giftCard }) => ({
  type: DELETE_GIFT_CARD,
  payload: { giftCard }
});

export const deleteGiftCardSuccess = ({ giftCard }) => ({
  type: DELETE_GIFT_CARD_SUCCESS,
  payload: { giftCard }
});

export const deleteGiftCardFailed = ({ error }) => ({
  type: DELETE_GIFT_CARD_FAILED,
  payload: { error }
});

export const assignGiftCard = ({ fields }) => ({
  type: ASSIGN_GIFT_CARD,
  payload: { fields }
});
