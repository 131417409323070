import {
  CREATE_GIFT_CARD_API,
  INDEX_GIFT_CARDS_API,
  FETCH_GIFT_CARD_API,
  DELETE_GIFT_CARD_API,
  UPDATE_GIFT_CARD_API
} from "api/model/giftCard/constants";
import { generateRoute } from "helpers/routesUtils";
import api from "api/api";

export const apiListGiftCards = () => {
  const { url, method } = INDEX_GIFT_CARDS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateGiftCard = ({ giftCard }) => {
  const { url, method } = CREATE_GIFT_CARD_API;

  return api({
    method,
    url,
    data: giftCardParams({ giftCard }),
  });
};

export const apiFetchGiftCard = ({ giftCardId }) => {
  const { url, method } = FETCH_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, giftCardId),
  });
};

export const apiUpdateGiftCard = ({ giftCard }) => {
  const { url, method } = UPDATE_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, giftCard.id),
    data: giftCardParams({ giftCard }),
  });
};

export const apiDeleteGiftCard = ({ giftCard }) => {
  const { url, method } = DELETE_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, giftCard.id),
  });
};

const giftCardParams = ({ giftCard }) => {
  const {} = giftCard;

  return {
    giftCard: {

    }
  }
};
