import {
  CLEAR_ALL_RECEIVED_NOTICES,
  INIT_RECEIVED_NOTICES,
  LIST_RECEIVED_NOTICES,
  LIST_RECEIVED_NOTICES_FAILED,
  LIST_RECEIVED_NOTICES_SUCCESS
} from "state/model/receivedNotices/receivedNotices/constants";

export const initReceivedNotices = () => ({
  type: INIT_RECEIVED_NOTICES,
  payload: {}
});

export const listReceivedNotices = () => ({
  type: LIST_RECEIVED_NOTICES,
  payload: {}
});

export const listReceivedNoticesSuccess = ({ receivedNotices }) => ({
  type: LIST_RECEIVED_NOTICES_SUCCESS,
  payload: { receivedNotices }
});

export const listReceivedNoticesFailed = ({ error }) => ({
  type: LIST_RECEIVED_NOTICES_FAILED,
  payload: { error }
});

export const clearAllReceivedNotices = () => ({
  type: CLEAR_ALL_RECEIVED_NOTICES,
  payload: {}
});