import {
  INIT_GIFT_CARDS,
  LIST_GIFT_CARDS,
  LIST_GIFT_CARDS_FAILED,
  LIST_GIFT_CARDS_SUCCESS
} from "state/model/giftCards/giftCards/constants";

export const initGiftCards = () => ({
  type: INIT_GIFT_CARDS,
  payload: {}
});

export const listGiftCards = () => ({
  type: LIST_GIFT_CARDS,
  payload: {}
});

export const listGiftCardsSuccess = ({ giftCards }) => ({
  type: LIST_GIFT_CARDS_SUCCESS,
  payload: { giftCards }
});

export const listGiftCardsFailed = ({ error }) => ({
  type: LIST_GIFT_CARDS_FAILED,
  payload: { error }
});
