// @flow

import { combineReducers } from 'redux';
import modalReducers from 'state/ui/modal/modalReducers'
import imageEditorReducers from "state/ui/imageEditor/imageEditorReducers";
import formReducers from "state/ui/form/formReducers";

export default combineReducers({
  modal: modalReducers,
  form: formReducers,
  imageEditor: imageEditorReducers,
});
