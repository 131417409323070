import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_RECEIVED_NOTICE,
  DELETE_RECEIVED_NOTICE,
  FETCH_RECEIVED_NOTICE,
  UPDATE_RECEIVED_NOTICE, UPDATE_RECEIVED_NOTICE_READ
} from "state/model/receivedNotices/receivedNotice/constants";
import {
  apiCreateReceivedNotice,
  apiDeleteReceivedNotice,
  apiFetchReceivedNotice,
  apiUpdateReceivedNotice
} from "api/notification/receivedNotice/apiReceivedNotice";
import {
  createReceivedNoticeFailed,
  createReceivedNoticeSuccess,
  deleteReceivedNoticeFailed,
  deleteReceivedNoticeSuccess,
  fetchReceivedNoticeFailed,
  fetchReceivedNoticeSuccess,
  initReceivedNotice,
  updateReceivedNoticeFailed,
  updateReceivedNoticeSuccess
} from "state/model/receivedNotices/receivedNotice/receivedNoticeActions";
import { toastDeleted, toastError, toastSaved } from "state/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "state/ui/modal/modalActions";
import { INDEX_RECEIVED_NOTICES_PATH } from "routes/receivedNotice/constants";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { generateRoute } from "helpers/routesUtils";
import history from 'routes/history';


function* receivedNoticeSaga() {
  yield all([
    fork(watchFetchReceivedNotice),
    fork(watchUpdateReceivedNotice),
    fork(watchCreateReceivedNotice),
    fork(watchDeleteReceivedNotice),
    fork(watchUpdateReceivedNoticeRead),
  ]);
}

// Fetch ReceivedNotice
export function* watchFetchReceivedNotice() {
  yield takeEvery(FETCH_RECEIVED_NOTICE, sagaFetchReceivedNotice);
}

export function* sagaFetchReceivedNotice({ payload }) {
  try {
    const response = yield call(apiFetchReceivedNotice, payload);

    const { receivedNotice } = response.data;
    yield put(fetchReceivedNoticeSuccess({ receivedNotice }));

  } catch (e) {
    yield put(fetchReceivedNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ReceivedNotice
export function* watchCreateReceivedNotice() {
  yield takeLeading(CREATE_RECEIVED_NOTICE, sagaCreateReceivedNotice);
}

export function* sagaCreateReceivedNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateReceivedNotice, payload);

    const { receivedNotice } = response.data;
    yield put(createReceivedNoticeSuccess({ receivedNotice }));
    yield put(toastSaved({ name: `ReceivedNotice` }));


    history.push(generateRoute(INDEX_RECEIVED_NOTICES_PATH));
    yield put(initReceivedNotice());


  } catch (e) {
    yield put(createReceivedNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ReceivedNotice
export function* watchUpdateReceivedNotice() {
  yield takeLeading(UPDATE_RECEIVED_NOTICE, sagaUpdateReceivedNotice);
}

export function* sagaUpdateReceivedNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateReceivedNotice, payload);

    const { receivedNotice } = response.data;
    yield put(updateReceivedNoticeSuccess({ receivedNotice }));
    yield put(toastSaved({ name: 'ReceivedNotice' }));
    history.push(generateRoute(INDEX_RECEIVED_NOTICES_PATH));
    yield put(initReceivedNotice());

  } catch (e) {
    yield put(updateReceivedNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ReceivedNotice
export function* watchDeleteReceivedNotice() {
  yield takeLeading(DELETE_RECEIVED_NOTICE, sagaDeleteReceivedNotice);
}

export function* sagaDeleteReceivedNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteReceivedNotice, payload);

    const { receivedNotice } = response.data;
    yield put(deleteReceivedNoticeSuccess({ receivedNotice }));
    yield put(toastDeleted({ name: 'ReceivedNotice' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteReceivedNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ReceivedNotices
export function* watchUpdateReceivedNoticeRead() {
  yield takeEvery(UPDATE_RECEIVED_NOTICE_READ, sagaUpdateReceivedNoticeRead);
}

export function* sagaUpdateReceivedNoticeRead({ payload }) {
  try {
    const response = yield call(apiUpdateReceivedNotice, {
      receivedNotice: {
        ...payload.receivedNotice,
        isRead: true
      }
    });

  } catch (e) {
    throwFrontError(e);
  }
}


export default receivedNoticeSaga;
