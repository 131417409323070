import _ from 'lodash';

export const extractAllDigitalFrom = (string) => {
  return string.match(/\d/g).join('');
};

export const extractIdFrom = (string) => {
  const matches = !!string ? string.match(/__([\w-]+)/) : null;
  return !!matches ? matches[0].replace('__', '') : string;
};

export const extractClassNameFrom = (string) => {
  const matches = string.match(/([^\W_]+)_/);
  return !!matches ? matches[0].replace('_', '') : string;
};

export const extractDecimalFrom = (string) => {
  return string.match(/\d+[.]?\d*/);
};

export const randomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const generateDummyId = (className) => {
  return `${_.camelCase(className)}_${randomString()}`
};

export const extractSkuFrom = (productTitle) => {
  const matches = productTitle.match(/(\w+)\s--\s/);

  return !!matches ? matches[0].replace(' -- ', '') : '';
};
