import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_THEME,
  CHECK_USER,
  CLEAR_USER,
  FETCH_CURRENT_STAFF,
  FETCH_CURRENT_STAFF_FAILED,
  FETCH_CURRENT_STAFF_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD_SUCCESS,
  LOG_PATH,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REFRESH_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SETUP_ACCOUNT,
  SETUP_ACCOUNT_FAILED,
  SETUP_ACCOUNT_SUCCESS,
} from 'state/auth/authConstants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username, password): AuthAction => ({
  type: LOGIN_USER,
  payload: { username, password },
});

export const loginUserSuccess = ({ token, user }): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: { token, user },
});

export const loginUserFailed = ({ error }): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: { error },
});

export const logoutUser = (): AuthAction => ({
  type: LOGOUT_USER,
  payload: {},
});

export const logoutUserSuccess = (): AuthAction => ({
  type: LOGOUT_USER_SUCCESS,
  payload: {},
});

export const clearUser = (): AuthAction => ({
  type: CLEAR_USER,
  payload: {},
});

export const logPath = (path): AuthAction => ({
  type: LOG_PATH,
  payload: { path: path },
});

export const checkUser = () => ({
  type: CHECK_USER,
  payload: {}
});

export const refreshUser = ({ current }) => ({
  type: REFRESH_USER,
  payload: { current }
});

export const changeTheme = ({ userId, theme }): AuthAction => ({
  type: CHANGE_THEME,
  payload: { userId, theme }
});

export const forgetPassword = ({ username }): AuthAction => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = ({ passwordResetStatus }) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: { passwordResetStatus }
});

export const forgetPasswordFailed = ({ error }) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: { error }
});

export const resetPassword = ({ resetPasswordToken, password, passwordConfirmation }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordToken, password, passwordConfirmation }
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: {}
});

export const resetPasswordFailed = ({ error }) => ({
  type: RESET_PASSWORD_FAILED,
  payload: { error }
});

export const changePassword = ({ currentPassword, password, passwordConfirmation }) => ({
  type: CHANGE_PASSWORD,
  payload: { currentPassword, password, passwordConfirmation }
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: {}
});

export const changePasswordFailed = ({ error }) => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: { error }
});

export const setupAccount = ({ resetPasswordToken, password, passwordConfirmation }) => ({
  type: SETUP_ACCOUNT,
  payload: { resetPasswordToken, password, passwordConfirmation }
});

export const setupAccountSuccess = () => ({
  type: SETUP_ACCOUNT_SUCCESS,
  payload: {}
});

export const setupAccountFailed = ({ error }) => ({
  type: SETUP_ACCOUNT_FAILED,
  payload: { error }
});

export const fetchCurrentStaff = () => ({
  type: FETCH_CURRENT_STAFF,
  payload: {}
});

export const fetchCurrentStaffSuccess = ({ current }) => ({
  type: FETCH_CURRENT_STAFF_SUCCESS,
  payload: { current },
});

export const fetchCurrentStaffFailed = ({ error }) => ({
  type: FETCH_CURRENT_STAFF_FAILED,
  payload: { error }
});
