// @flow

import { combineReducers } from 'redux';

import layout from 'state/layout/reducers';
import appMenu from 'state/appMenu/reducers';
import auth from 'state/auth/authReducers';
import ui from 'state/ui/uiReducers';
import model from 'state/model/modelReducers';

export default combineReducers({
    auth,
    appMenu,
    layout,
    ui,
    model,
});
