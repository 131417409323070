import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { TOAST_CYPRESS, TOAST_DEFAULT, TOAST_DELETED, TOAST_ERROR, TOAST_SAVED } from "state/ui/uiConstants";
import { throwFrontError } from "helpers/errorUtils";

function* uiToastSaga() {
  yield all([
    fork(watchToastDefault),
    fork(watchToastError),
    fork(watchToastSaved),
    fork(watchToastDeleted),
    fork(watchToastCypress),
  ]);
}

// Toast Default
export function* watchToastDefault() {
  yield takeEvery(TOAST_DEFAULT, sagaToastDefault);
}

export function* sagaToastDefault({ payload }) {
  try {
    yield call(toast.info, `${ payload.message }`);

  } catch (e) {
    throwFrontError(e);
  }
}

// Toast Error
export function* watchToastError() {
  yield takeEvery(TOAST_ERROR, sagaToastError);
}

export function* sagaToastError({ payload }) {
  try {
    yield call(toast.error, `${ payload.message }`);

  } catch (e) {
    throwFrontError(e);
  }
}

// Toast Saved
export function* watchToastSaved() {
  yield takeEvery(TOAST_SAVED, sagaToastSaved);
}

export function* sagaToastSaved({ payload }) {
  try {
    yield call(toast.success, `${ payload.name } has been saved`);

  } catch (e) {
    throwFrontError(e);
  }
}

// Toast Deleted
export function* watchToastDeleted() {
  yield takeEvery(TOAST_DELETED, sagaToastDeleted);
}

export function* sagaToastDeleted({ payload }) {
  try {
    yield call(toast.info, `${ payload.name } has been deleted`);

  } catch (e) {
    throwFrontError(e);
  }
}

// Toast Cypress
export function* watchToastCypress() {
  yield takeEvery(TOAST_CYPRESS, sagaToastCypress);
}

export function* sagaToastCypress({ payload }) {
  try {
    yield call(toast.warn, `${ payload.message }`, { className: 'cypressToast' });

  } catch (e) {
    throwFrontError(e);
  }
}

export default uiToastSaga;
