import {
  FETCH_PRODUCT_GIFT_CARD,
  FETCH_PRODUCT_GIFT_CARD_FAILED,
  FETCH_PRODUCT_GIFT_CARD_SUCCESS,
  INIT_PRODUCT_GIFT_CARD,
  ASSIGN_PRODUCT_GIFT_CARD
} from "state/model/productGiftCards/productGiftCard/constants";

const INIT_STATE = {
  productGiftCard: {},
  loading: true,
  error: null,
};

const productGiftCardReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_GIFT_CARD:
      return INIT_STATE;

    case FETCH_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productGiftCard: action.payload.productGiftCard,
      };

    case FETCH_PRODUCT_GIFT_CARD_FAILED:
      return {
        ...state,
        productGiftCard: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PRODUCT_GIFT_CARD:
      return {
        ...state,
        productGiftCard: {
          ...state.productGiftCard,
          ...action.payload.fields,
        }
      }

    default:
      return { ...state };
  }
};

export default productGiftCardReducers;
