import { ASSIGN_IMAGE_EDITOR_IMAGE_64, INIT_IMAGE_EDITOR, PROCESS_IMAGE } from "state/ui/uiConstants";

const INIT_STATE = {
  image64: null,
  processedImage64: null,
};

const ImageEditorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_IMAGE_EDITOR:
      return INIT_STATE;

    case ASSIGN_IMAGE_EDITOR_IMAGE_64:
      return {
        ...state,
        image64: action.payload.image64,
      };

    case PROCESS_IMAGE:
      return {
        ...state,
        processedImage64: action.payload.processedImage64,
      };

    default:
      return { ...state };
  }
};

export default ImageEditorReducer;
