/**
 * Checks if user is authenticated
 */

import history from "routes/history";
import { LOGIN_PATH } from 'routes/auth/constants';
import { isTesting } from "helpers/testingUtils";

const generateAuthToken = (token) => {
  return `Bearer ${ token }`;
};

const initJwtToken = () => {
  return localStorage.getItem('token');
};

const setLocalToken = (token) => {
  localStorage.setItem('token', token);
};

const getLocalToken = () => {
  return localStorage.getItem('token');
};

const initIsLoggedIn = () => {
  return !!localStorage.getItem('token');
};

const isUserAuthenticated = () => {
  return !!localStorage.getItem('token');
};

const logoutLocally = () => {
  const promise = new Promise(function (resolve, reject) {
    clearLocalStorageToken();
    resolve(true);
  });

  promise.then(bool => redirectToLogin());
};

const clearLocalStorageToken = () => {
  localStorage.removeItem('token');
};

const redirectToLogin = () => {
  history.push(LOGIN_PATH);

  if (!isTesting()) {
    history.go(0);
  }
};

export {
  generateAuthToken,
  initJwtToken,
  initIsLoggedIn,
  logoutLocally,
  setLocalToken,
  getLocalToken,
  clearLocalStorageToken,
  redirectToLogin,
  isUserAuthenticated,
};
