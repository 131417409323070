import _ from 'lodash';


Date.prototype.addDays = function(days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export const provideDelay = ({ fn }, next) => (fn.name === 'delayP') ? null : next();

export const cypressAttr = (prefix, target) => {
  return `${ prefix }_${ _.camelCase(target) }`;
};

export const updated = (value) => {
  if (typeof value === "boolean") return !value;

  if (typeof value === "number") return value + 10;

  if (typeof value === "string") return `updated_${ value }`;

  if (value instanceof Date) return value.addDays(5);

  return null
};

export const isTesting = () => {
  return process.env.JEST_WORKER_ID !== undefined;
}