import { all, call, fork, put, takeEvery, takeLeading, delay } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD,
  CHECK_USER,
  CLEAR_USER,
  FORGET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD, SETUP_ACCOUNT
} from 'state/auth/authConstants';
import {
  apiChangePassword,
  apiCheckUser,
  apiForgetPassword,
  apiResetPassword,
  apiSignInUser,
  apiSignOutUser
} from "api/auth/authApi";
import {
  changePasswordFailed,
  changePasswordSuccess,
  clearUser,
  forgetPasswordFailed,
  forgetPasswordSuccess,
  loginUserFailed,
  loginUserSuccess,
  logoutUser,
  logoutUserSuccess,
  refreshUser, resetPasswordFailed, resetPasswordSuccess, setupAccountFailed, setupAccountSuccess,
} from 'state/auth/authActions';
import { clearLocalStorageToken, logoutLocally, redirectToLogin, setLocalToken, } from "helpers/authUtils";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { LOGOUT_PATH } from "routes/auth/constants";
import history from 'routes/history';
import { toastCypress, toastDefault } from "state/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";


function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchClearUser),
    fork(watchCheckUser),
    // fork(watchChangeTheme),
    fork(watchForgetPassword),
    fork(watchResetPassword),
    fork(watchChangePassword),
    fork(watchSetupAccount),
    // fork(watchFetchCurrentStaff),
  ]);
}

// Login User
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, sagaLogin);
}

export function* sagaLogin({ payload }) {
  try {
    const response = yield call(apiSignInUser, payload);
    setLocalToken(response.data.token);
    yield put(loginUserSuccess(response.data));

  } catch (e) {
    yield put(loginUserFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Logout User
export function* watchLogoutUser() {
  yield takeLeading(LOGOUT_USER, sagaLogout);
}

export function* sagaLogout() {
  try {
    const response = yield call(apiSignOutUser);
    yield call(() => {
      logoutLocally();
    });
    yield put(logoutUserSuccess());

  } catch (e) {
    yield call(() => {
      logoutLocally();
    });
    throwFrontError(e);
  }
}

// Clear User
export function* watchClearUser() {
  yield takeEvery(CLEAR_USER, sagaClearUser);
}

export function* sagaClearUser() {
  try {
    yield call(() => {
      clearLocalStorageToken();
    });

  } catch (e) {
    yield call(() => {
      clearLocalStorageToken();
    });
    throwFrontError(e);
  }
}

// Check User
export function* watchCheckUser() {
  yield takeEvery(CHECK_USER, sagaCheckUser);
}

export function* sagaCheckUser() {
  try {
    const response = yield call(apiCheckUser);

    if (!response.data.currentAuth) {
      history.push(LOGOUT_PATH);
    } else {
      yield put(refreshUser({ current: response.data }));
    }


  } catch (e) {
    yield put(logoutUser());
    // yield put(initMenu());
    throwFrontError(e);
  }
}

//
// // Watch Change Theme
// export function* watchChangeTheme() {
//   yield takeEvery(CHANGE_THEME, sagaChangeTheme);
// }
//
// export function* sagaChangeTheme({ payload }) {
//   try {
//     const { userId, theme } = payload;
//     const response = yield call(apiChangeTheme, userId, theme);
//     yield put(refreshUser({ current: response.data }));
//
//   } catch (e) {
//     yield put(logoutUser());
//     yield put(initMenu());
//     throwFrontError(e);
//   }
// }

// Watch Forget Password
export function* watchForgetPassword() {
  yield takeLeading(FORGET_PASSWORD, sagaForgetPassword);
}

export function* sagaForgetPassword({ payload }) {
  try {
    const response = yield call(apiForgetPassword, payload);
    yield put(forgetPasswordSuccess({ passwordResetStatus: response.data.status }));

    if (!!response.data.cypress) {
      yield put(toastCypress({ message: response.data.cypress }));
    }

  } catch (e) {
    yield put(forgetPasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Reset Password
export function* watchResetPassword() {
  yield takeLeading(RESET_PASSWORD, sagaResetPassword);
}

export function* sagaResetPassword({ payload }) {
  try {
    const response = yield call(apiResetPassword, payload);

    yield put(resetPasswordSuccess());
    yield put(clearUser());
    yield put(toastDefault({ message: 'Password has been reset. Please login to proceed.' }));
    yield call(() => {
      redirectToLogin();
    });

  } catch (e) {
    yield put(resetPasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Change Password
export function* watchChangePassword() {
  yield takeLeading(CHANGE_PASSWORD, sagaChangePassword);
}

export function* sagaChangePassword({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiChangePassword, payload);

    yield put(changePasswordSuccess());
    yield put(logoutUser());
    yield put(toastDefault({ message: 'Password has been updated. Please login to proceed.' }));

  } catch (e) {
    yield put(changePasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Setup Account
export function* watchSetupAccount() {
  yield takeLeading(SETUP_ACCOUNT, sagaSetupAccount);
}

export function* sagaSetupAccount({ payload }) {
  try {
    const response = yield call(apiResetPassword, payload);

    yield call(() => {
      setLocalToken(response.data.token);
    })
    history.push(OVERVIEW_DASHBOARD_PATH);

    yield put(setupAccountSuccess());
    yield put(loginUserSuccess(response.data));
    yield put(toastDefault({ message: 'Welcome.' }));

  } catch (e) {
    yield put(setupAccountFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// // Watch Fetch Current Staff
// export function* watchFetchCurrentStaff() {
//   yield takeEvery(FETCH_CURRENT_STAFF, sagaFetchCurrentStaff);
// }
//
// export function* sagaFetchCurrentStaff({ payload }) {
//   try {
//     const response = yield call(apiFetchCurrentStaff);
//
//     yield put(fetchCurrentStaffSuccess({ current: response.data }));
//
//   } catch (e) {
//     yield put(fetchCurrentStaffFailed(generateErrorMessage(e)));
//     throwFrontError(e);
//   }
// }

export default authSaga;
