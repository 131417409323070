import React from "react";
import { BASE_GIFT_CARD_PATH, INDEX_GIFT_CARDS_PATH, NEW_GIFT_CARD_PATH, SHOW_GIFT_CARD_PATH } from "routes/giftCard/constants";
import PrivateRoute from "routes/PrivateRoute";
import { INDEX_PRODUCT_GIFT_CARDS_PATH } from "routes/productGiftCard/constants";

const GiftCards = React.lazy(() => import('pages/giftCards/index/GiftCards'));
const NewGiftCard = React.lazy(() => import('pages/giftCards/new/NewGiftCard'));
const ShowGiftCard = React.lazy(() => import('pages/giftCards/show/ShowGiftCard'));

export const giftCardRoutes = {
  name: 'GiftCards',
  icon: 'far fa-ticket',
  path: INDEX_GIFT_CARDS_PATH,
  exact: true,
  component: GiftCards,
  route: PrivateRoute,
};

export const hiddenGiftCardRoutes = {
  children: [
    {
      path: SHOW_GIFT_CARD_PATH,
      exact: true,
      name: 'Show GiftCard',
      component: ShowGiftCard,
      route: PrivateRoute,
    }
  ]
}
