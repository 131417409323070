export const START_FIRST_LAYER_MODAL_LISTENING = 'START_FIRST_LAYER_MODAL_LISTENING';
export const STOP_FIRST_LAYER_MODAL_LISTENING = 'STOP_FIRST_LAYER_MODAL_LISTENING';
export const OPEN_FIRST_LAYER_MODAL = 'OPEN_FIRST_LAYER_MODAL';
export const CLOSE_FIRST_LAYER_MODAL = 'CLOSE_FIRST_LAYER_MODAL MODAL';

export const OPEN_SECOND_LAYER_MODAL = 'OPEN_SECOND_LAYER_MODAL';
export const CLOSE_SECOND_LAYER_MODAL = 'CLOSE_SECOND_LAYER_MODAL';
export const START_SECOND_LAYER_MODAL_LISTENING = 'START_SECOND_LAYER_MODAL_LISTENING';
export const STOP_SECOND_LAYER_MODAL_LISTENING = 'STOP_SECOND_LAYER_MODAL_LISTENING';

export const OPEN_THIRD_LAYER_MODAL = 'OPEN_THIRD_LAYER_MODAL';
export const CLOSE_THIRD_LAYER_MODAL = 'CLOSE_THIRD_LAYER_MODAL';
export const START_THIRD_LAYER_MODAL_LISTENING = 'START_THIRD_LAYER_MODAL_LISTENING';
export const STOP_THIRD_LAYER_MODAL_LISTENING = 'STOP_THIRD_LAYER_MODAL_LISTENING';

export const OPEN_FOURTH_LAYER_MODAL = 'OPEN_FOURTH_LAYER_MODAL';
export const CLOSE_FOURTH_LAYER_MODAL = 'CLOSE_FOURTH_LAYER_MODAL';
export const START_FOURTH_LAYER_MODAL_LISTENING = 'START_FOURTH_LAYER_MODAL_LISTENING';
export const STOP_FOURTH_LAYER_MODAL_LISTENING = 'STOP_FOURTH_LAYER_MODAL_LISTENING';


export const TOAST_DEFAULT = 'TOAST_DEFAULT';
export const TOAST_ERROR = 'TOAST_ERROR';
export const TOAST_SAVED = 'TOAST SAVED';
export const TOAST_DELETED = 'TOAST DELETED';
export const TOAST_CYPRESS = 'TOAST_CYPRESS';


export const INIT_IMAGE_EDITOR = 'INIT_IMAGE_EDITOR';
export const ASSIGN_IMAGE_EDITOR_IMAGE_64 = 'ASSIGN_IMAGE_EDITOR_IMAGE_64';
export const PROCESS_IMAGE = 'PROCESS_IMAGE';
