import _ from 'lodash';
import { extractIdFrom } from "helpers/stringUtils";

export const generateRoute = (route, id = null, params = {}) => {
  let result = route;

  if (id) {
    result = _.replace(route, ':id', extractIdFrom(id));
  }

  _.map(params, (value, param) => {
    result = _.replace(result, `:${ param }`, value);
  });

  return result;
};

export const isDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
};
