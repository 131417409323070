import _ from 'lodash';
import {
  CREATE_PRODUCT_GIFT_CARD_SUCCESS,
  DELETE_PRODUCT_GIFT_CARD_SUCCESS,
  UPDATE_PRODUCT_GIFT_CARD_SUCCESS
} from "state/model/productGiftCards/productGiftCard/constants";
import {
  INIT_PRODUCT_GIFT_CARDS,
  LIST_PRODUCT_GIFT_CARDS,
  LIST_PRODUCT_GIFT_CARDS_FAILED,
  LIST_PRODUCT_GIFT_CARDS_SUCCESS
} from "state/model/productGiftCards/productGiftCards/constants";

const INIT_STATE = {
  productGiftCards: {},
  loading: true,
  error: null,
};

const productGiftCardsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_GIFT_CARDS:
      return INIT_STATE;

    case LIST_PRODUCT_GIFT_CARDS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PRODUCT_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productGiftCards: _.mapKeys(action.payload.productGiftCards, 'id'),
      };

    case LIST_PRODUCT_GIFT_CARDS_FAILED:
      return {
        ...state,
        productGiftCards: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        productGiftCards: {
          [action.payload.productGiftCard.id]: action.payload.productGiftCard,
          ...state.productGiftCards,
        },
      };

    case UPDATE_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        productGiftCards: {
          ...state.productGiftCards,
          [action.payload.productGiftCard.id]: action.payload.productGiftCard,
        },
      };

    case DELETE_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        productGiftCards: _.omit(state.productGiftCards, action.payload.productGiftCard.id)
      };

    default:
      return { ...state };
  }
};

export default productGiftCardsReducers;
