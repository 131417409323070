import {
  ERROR_FORM_LINE_ATTACHMENT,
  LOADING_FORM_LINE_ATTACHMENT,
  RESET_FORM_LINE_ATTACHMENT
} from "state/ui/form/lineAttachment/constants";
import {
  CREATE_LINE_ATTACHMENT,
  CREATE_LINE_ATTACHMENT_FAILED,
  CREATE_LINE_ATTACHMENT_SUCCESS,
  UPDATE_LINE_ATTACHMENT, UPDATE_LINE_ATTACHMENT_FAILED, UPDATE_LINE_ATTACHMENT_SUCCESS
} from "state/model/lineAttachments/lineAttachment/constants";


const INIT_STATE = {
  uploadingCount: 0,
  loading: false,
  error: null,
};

const formLineAttachmentReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_LINE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_LINE_ATTACHMENT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_LINE_ATTACHMENT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_LINE_ATTACHMENT:
      return {
        ...state,
        uploadingCount: state.uploadingCount + 1,
        loading: true,
        error: null
      };
    case CREATE_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        uploadingCount: state.uploadingCount - 1,
        loading: false,
        error: null
      };
    case CREATE_LINE_ATTACHMENT_FAILED:
      return {
        ...state,
        uploadingCount: state.uploadingCount - 1,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_LINE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_LINE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formLineAttachmentReducers;
