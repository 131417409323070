import { all } from 'redux-saga/effects';

import layoutSaga from 'state/layout/saga';
import authSaga from 'state/auth/authSaga';
import appMenuSaga from 'state/appMenu/saga';
import uiSaga from "state/ui/uiSaga";
import modelSaga from "state/model/modelSaga";

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    layoutSaga(),
    appMenuSaga(),
    uiSaga(),
    modelSaga(),
  ]);
}
