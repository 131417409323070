import _ from 'lodash';
import { isDevelopment } from "helpers/routesUtils";

export const generateErrorMessage = (error) => {
  const errorResponse = error.response;

  const errorMessage = errorResponse ? errorResponse.data.error : 'Server Error';

  return { error: errorMessage };
};

export const generateFormErrorMessage = (name, error) => {
  if (_.isArray(error)) {
    return _.chain(error)
      .map((message) => {
        return _.capitalize(`${ _.startCase(name) } ${ message }.`)
      })
      .join(' ')
      .value();
  }

  return _.capitalize(`${ _.startCase(name) } ${ error.message }.`)
};

export const formErrorClassName = (formError) => {
  return !!formError ? 'av-invalid' : '';
};

export const labelErrorClassName = (formError, inputName) => {
  return !!formError && !!formError[inputName] ? 'text-danger is-invalid' : ''
};

export const isInputInvalid = (formError, inputName) => {
  return !!formError && !!formError[inputName];
};

export const isTabInvalid = (formError, errorKeyPrefix) => {
  let isInvalid = false;

  if (!!formError) {
    _.forEach(_.keys(formError), key => {
      if (_.startsWith(key, errorKeyPrefix)) {
        isInvalid = true;
        return false;
      }
    })
  }

  return isInvalid;
};

export const feedbackErrorClassName = (formError, inputName) => {
  return !!formError && !!formError[inputName] ? 'invalid-feedback' : ''
};

export const feedbackErrorMessage = (formError, inputName, errorKey, defaultMessage) => {
  return !!formError && !!formError[errorKey] ?
    generateFormErrorMessage(inputName, formError[errorKey])
    : defaultMessage;
};

export const throwFrontError = (e) => {
  if (isDevelopment()) {
    if (!!e.response) {
      console.error(e.response.data.error);

    } else {
      throw e;
    }
  }
};
