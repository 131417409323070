export const INIT_PRODUCT_GIFT_CARD = 'INIT_PRODUCT_GIFT_CARD';

export const FETCH_PRODUCT_GIFT_CARD = 'FETCH_PRODUCT_GIFT_CARD';
export const FETCH_PRODUCT_GIFT_CARD_SUCCESS = 'FETCH_PRODUCT_GIFT_CARD_SUCCESS';
export const FETCH_PRODUCT_GIFT_CARD_FAILED = 'FETCH_PRODUCT_GIFT_CARD_FAILED';

export const CREATE_PRODUCT_GIFT_CARD = 'CREATE_PRODUCT_GIFT_CARD';
export const CREATE_PRODUCT_GIFT_CARD_SUCCESS = 'CREATE_PRODUCT_GIFT_CARD_SUCCESS';
export const CREATE_PRODUCT_GIFT_CARD_FAILED = 'CREATE_PRODUCT_GIFT_CARD_FAILED';

export const UPDATE_PRODUCT_GIFT_CARD = 'UPDATE_PRODUCT_GIFT_CARD';
export const UPDATE_PRODUCT_GIFT_CARD_SUCCESS = 'UPDATE_PRODUCT_GIFT_CARD_SUCCESS';
export const UPDATE_PRODUCT_GIFT_CARD_FAILED = 'UPDATE_PRODUCT_GIFT_CARD_FAILED';

export const DELETE_PRODUCT_GIFT_CARD = 'DELETE_PRODUCT_GIFT_CARD';
export const DELETE_PRODUCT_GIFT_CARD_SUCCESS = 'DELETE_PRODUCT_GIFT_CARD_SUCCESS';
export const DELETE_PRODUCT_GIFT_CARD_FAILED = 'DELETE_PRODUCT_GIFT_CARD_FAILED';

export const ASSIGN_PRODUCT_GIFT_CARD = 'ASSIGN_PRODUCT_GIFT_CARD';
