import {
  ERROR_FORM_PRODUCT_GIFT_CARD,
  LOADING_FORM_PRODUCT_GIFT_CARD,
  RESET_FORM_PRODUCT_GIFT_CARD
} from "state/ui/form/productGiftCard/constants";
import {
  CREATE_PRODUCT_GIFT_CARD,
  CREATE_PRODUCT_GIFT_CARD_FAILED,
  CREATE_PRODUCT_GIFT_CARD_SUCCESS,
  UPDATE_PRODUCT_GIFT_CARD, UPDATE_PRODUCT_GIFT_CARD_FAILED, UPDATE_PRODUCT_GIFT_CARD_SUCCESS
} from "state/model/productGiftCards/productGiftCard/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formProductGiftCardReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRODUCT_GIFT_CARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRODUCT_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRODUCT_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRODUCT_GIFT_CARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formProductGiftCardReducers;
