import {
  INIT_PRODUCT_GIFT_CARDS,
  LIST_PRODUCT_GIFT_CARDS,
  LIST_PRODUCT_GIFT_CARDS_FAILED,
  LIST_PRODUCT_GIFT_CARDS_SUCCESS
} from "state/model/productGiftCards/productGiftCards/constants";

export const initProductGiftCards = () => ({
  type: INIT_PRODUCT_GIFT_CARDS,
  payload: {}
});

export const listProductGiftCards = () => ({
  type: LIST_PRODUCT_GIFT_CARDS,
  payload: {}
});

export const listProductGiftCardsSuccess = ({ productGiftCards }) => ({
  type: LIST_PRODUCT_GIFT_CARDS_SUCCESS,
  payload: { productGiftCards }
});

export const listProductGiftCardsFailed = ({ error }) => ({
  type: LIST_PRODUCT_GIFT_CARDS_FAILED,
  payload: { error }
});
