import { combineReducers } from 'redux';
import lineAttachmentReducers from "state/model/lineAttachments/lineAttachment/lineAttachmentReducers";
import lineAttachmentsReducers from "state/model/lineAttachments/lineAttachments/lineAttachmentsReducers";
import receivedNoticeReducers from "state/model/receivedNotices/receivedNotice/receivedNoticeReducers";
import receivedNoticesReducers from "state/model/receivedNotices/receivedNotices/receivedNoticesReducers";
import productGiftCardReducers from "state/model/productGiftCards/productGiftCard/productGiftCardReducers";
import productGiftCardsReducers from "state/model/productGiftCards/productGiftCards/productGiftCardsReducers";
import giftCardReducers from "state/model/giftCards/giftCard/giftCardReducers";
import giftCardsReducers from "state/model/giftCards/giftCards/giftCardsReducers";


export default combineReducers({
  lineAttachment: lineAttachmentReducers,
  lineAttachments: lineAttachmentsReducers,
  receivedNotice: receivedNoticeReducers,
  receivedNotices: receivedNoticesReducers,
  productGiftCard: productGiftCardReducers,
  productGiftCards: productGiftCardsReducers,
  giftCard: giftCardReducers,
  giftCards: giftCardsReducers,
});
