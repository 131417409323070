import React from 'react';
import { Slide, ToastContainer } from "react-toastify";

class ConfigToast extends React.Component {
  render = () => {
    return (
      <ToastContainer
        position="bottom-center"
        autoClose={ 5000 }
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={ false }
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        transition={ Slide }
      />
    );
  }
}

export default ConfigToast;
