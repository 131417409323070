import { combineReducers } from 'redux';
import formLineAttachmentReducers from "state/ui/form/lineAttachment/formLineAttachmentReducers";
import formReceivedNoticeReducers from "state/ui/form/receivedNotice/formReceivedNoticeReducers";
import formProductGiftCardReducers from "state/ui/form/productGiftCard/formProductGiftCardReducers";
import formGiftCardReducers from "state/ui/form/giftCard/formGiftCardReducers";

export default combineReducers({
  lineAttachment: formLineAttachmentReducers,
  receivedNotice: formReceivedNoticeReducers,
  productGiftCard: formProductGiftCardReducers,
  giftCard: formGiftCardReducers,
});
